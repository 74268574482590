import React from 'react'
import Link from 'gatsby-link'
import styles from './BlogPosts.module.css'

export default ({ data }) => {
  return (
    <div className={styles.outerContainer}>
      <div>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id} className={styles.blogCard}>
            <Link to={node.fields.slug}>
              <span className={styles.blogTitle}>{node.frontmatter.title}</span>
            </Link>
            <p className={styles.blogDate}>{node.frontmatter.date}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
