import React from 'react'
import styles from './Intro.module.css'
const Intro = () => {
  return (
    <div className={styles.intro}>
      <div className={styles.introHeader}>
        <p className={styles.hey}>Hello There</p>
        <p className={styles.heyja}>こんにちは</p>
        <p className={styles.subtitle}>
          I'm{' '}
          <a
            className={styles.author}
            href="https://twitter.com/chantlong"
            target="_blank"
            rel="noreferrer"
          >
            Alex
          </a>
          , I polish Software from a UX perspective throughout the stack. Based
          in Tokyo, sometimes Toronto & Hong Kong.
        </p>
        <p className={styles.subtitle}>
          <a
            className={styles.author}
            href="https://twitter.com/chantlong"
            target="_blank"
            rel="noreferrer"
          >
            アレックス
          </a>
          です。ソフトウェアのフロントからバックまでのUXを磨く。東京を拠点にしていて、ときどきトロントと香港。
        </p>
      </div>
    </div>
  )
}

export default Intro
