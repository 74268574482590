import React from 'react'
import Helmet from 'react-helmet'
import Intro from './../components/Intro'
import BlogPosts from './../components/BlogPosts'
import { graphql } from 'gatsby'
import styles from './Index.module.css'
export default ({ data }) => {
  return (
    <div className={styles.body}>
      <Helmet
        title="Alex Wong"
        meta={[
          {
            name: 'description',
            content:
              'Alex Wong is an All-Around Software Engineer based in Tokyo',
          },
          { name: 'keywords', content: 'Alex Wong, chantlong' },
        ]}
      />
      <Intro data={data} />
      <BlogPosts data={data} />
    </div>
  )
}

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
